import * as Yup from 'yup';

const validFileExtensions: string[] = ['doc', 'docx', 'pdf', 'xml', 'xls', 'xlsx', 'zip'];

function isValidFileType(file: any): boolean {
  const fileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
  return validFileExtensions.includes(fileType);
}

export const fileUploadValidationSchema = Yup.object({
  name:
    Yup.string()
      .label('Name')
      .required()
      .trim(),
  fileableId:
    Yup.string()
      .required()
      .label('Department'),
  file:
    Yup.mixed()
      .required()
      .label('File')
      .test('is-valid-type', "Type of file is invalid", (file) => isValidFileType(file))
})