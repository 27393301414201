import { createBrowserRouter } from 'react-router-dom';
import DashboardLayout from './components/Layouts/DashboardLayout';
import AuthLayout from './components/Layouts/AuthLayout';
// import PagesIndex from "./pages/Pages/Index";
// import PagesCreate from "./pages/Pages/Create/Index";
import DocumentIndex from "./views/Documents/DocumentsPage/DocumentsPage";
// import ProfilePage from "./views/Profile/ProfilePage";
import UsersPage from "./views/Users/UsersPage/UsersPage";
// import EditUserPage from "./views/Users/EditUserPage/EditUserPage";
// import CarriersPage from "./views/Carriers/CarriersPage/CarriersPage";

const router = createBrowserRouter([
  {
    path: '/login',
    element: <AuthLayout />,
  },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      // {
      //   path: 'pages',
      //   element: <PagesIndex/>,
      //   children: [
      //     {
      //       path: 'create',
      //       element: <PagesCreate/>
      //     }
      //   ],
      // },
      {
        path: 'documents',
        element: <DocumentIndex />
      },
      // {
      //   path: 'profile',
      //   element: <ProfilePage />
      // },
      {
        path: 'users',
        element: <UsersPage/>,
      },
      // {
      //   path: 'users/:id/edit',
      //   element: <EditUserPage/>,
      // },
      // {
      //   path: 'carriers',
      //   element: <CarriersPage/>
      // }
    ]
  },
]);

export default router;