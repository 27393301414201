import React from 'react';
import Badge from '../../../../components/UI/Badge';
import {Link} from 'react-router-dom';

const UsersListSkeleton = () => {
  return (
    <table className="min-w-full divide-y divide-slate-300">
      <tbody className="divide-y divide-slate-200">
        <tr>
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="flex items-center">
                <div className="flex w-48 h-4 bg-slate-200 animate-pulse"></div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-24 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 sm:pr-0">
            <span className='flex w-6 h-4 bg-slate-200 animate-pulse'></span>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="flex items-center">
                <div className="flex w-48 h-4 bg-slate-200 animate-pulse"></div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-24 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 sm:pr-0">
            <span className='flex w-6 h-4 bg-slate-200 animate-pulse'></span>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="flex items-center">
                <div className="flex w-48 h-4 bg-slate-200 animate-pulse"></div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-24 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 sm:pr-0">
            <span className='flex w-6 h-4 bg-slate-200 animate-pulse'></span>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="flex items-center">
                <div className="flex w-48 h-4 bg-slate-200 animate-pulse"></div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-24 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 sm:pr-0">
            <span className='flex w-6 h-4 bg-slate-200 animate-pulse'></span>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="flex items-center">
                <div className="flex w-48 h-4 bg-slate-200 animate-pulse"></div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-24 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 sm:pr-0">
            <span className='flex w-6 h-4 bg-slate-200 animate-pulse'></span>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="flex items-center">
                <div className="flex w-48 h-4 bg-slate-200 animate-pulse"></div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-24 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 sm:pr-0">
            <span className='flex w-6 h-4 bg-slate-200 animate-pulse'></span>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="flex items-center">
                <div className="flex w-48 h-4 bg-slate-200 animate-pulse"></div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-24 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 sm:pr-0">
            <span className='flex w-6 h-4 bg-slate-200 animate-pulse'></span>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="flex items-center">
                <div className="flex w-48 h-4 bg-slate-200 animate-pulse"></div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-24 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 sm:pr-0">
            <span className='flex w-6 h-4 bg-slate-200 animate-pulse'></span>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="flex items-center">
                <div className="flex w-48 h-4 bg-slate-200 animate-pulse"></div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-24 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 sm:pr-0">
            <span className='flex w-6 h-4 bg-slate-200 animate-pulse'></span>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
            <div className="flex items-center">
              <div className="flex items-center">
                <div className="flex w-48 h-4 bg-slate-200 animate-pulse"></div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-24 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="whitespace-nowrap px-3 py-3">
            <span className='flex w-10 h-4 bg-slate-200 animate-pulse'></span>
          </td>
          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 sm:pr-0">
            <span className='flex w-6 h-4 bg-slate-200 animate-pulse'></span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default UsersListSkeleton;