import React, {Fragment} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';

const userNavigation = [
  { name: 'My profile', href: '/profile' },
]

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

interface UserProps {
  id: number,
  firstName: string,
  lastName: string,
  email: string
}

const UserDropdown: React.FC<UserProps> = ({ id, firstName, lastName, email }) => {

  const getFirstLetters = (name: string): string => {
    return name.split(' ').map(word => word.charAt(0)).join('')
  }

  return (
    <div className="flex items-center gap-x-4 lg:gap-x-6">
      <Menu as="div" className="relative">
        <Menu.Button className="-m-1.5 flex items-center p-1.5">
          <span className="sr-only">Open user menu</span>
          <span className="hidden lg:flex lg:items-center">
            {/*<span className='flex items-center justify-center w-8 h-8 bg-sierra-blue text-white rounded-full font-bold text-sm'>*/}
            {/*  /!*{ getFirstLetters(name) }*!/*/}
            {/*</span>*/}
            <span className="text-sm font-semibold leading-6 text-slate-900 capitalize" aria-hidden="true">
             {firstName} {lastName}
            </span>
            {/*<ChevronDownIcon className="ml-2 h-5 w-5 text-slate-400" aria-hidden="true" />*/}
          </span>
        </Menu.Button>
        {/*<Transition*/}
        {/*  as={Fragment}*/}
        {/*  enter="transition ease-out duration-100"*/}
        {/*  enterFrom="transform opacity-0 scale-95"*/}
        {/*  enterTo="transform opacity-100 scale-100"*/}
        {/*  leave="transition ease-in duration-75"*/}
        {/*  leaveFrom="transform opacity-100 scale-100"*/}
        {/*  leaveTo="transform opacity-0 scale-95"*/}
        {/*>*/}
        {/*  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-max origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">*/}
        {/*    {userNavigation.map((item, index) => (*/}
        {/*      <Menu.Item key={index}>*/}
        {/*        {({ active }) => (*/}
        {/*          <a*/}
        {/*            href={item.href}*/}
        {/*            className={classNames(*/}
        {/*              active ? 'bg-gray-50' : '',*/}
        {/*              'block px-3 py-1 text-sm leading-6 text-slate-900'*/}
        {/*            )}*/}
        {/*          >*/}
        {/*            { item.name }*/}
        {/*          </a>*/}
        {/*        )}*/}
        {/*      </Menu.Item>*/}
        {/*    ))}*/}
        {/*  </Menu.Items>*/}
        {/*</Transition>*/}
      </Menu>
    </div>
  );
};

export default UserDropdown;