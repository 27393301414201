import React from 'react';
import Login from '../Login'

const AuthLayout = () => {
  return (
    <div className="flex h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-slate-900">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-28 w-auto"
          src="/logo.svg"
          alt="Your Company"
        />
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
        <Login/>
      </div>
    </div>
  )
};

export default AuthLayout;