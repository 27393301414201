import React from 'react'

export default function Select({name, label, options, value, meta, handleChange}: SelectProps) {
  const stylesList: string[] = ['relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-slate-900 outline-none sm:text-sm sm:leading-6']

  const handleSelect = (value: string | number | null) => {
   handleChange({ name: name, value: value })
  }

  return (
    <div className='flex flex-col'>
      <label htmlFor={name} className={["block text-sm font-medium leading-6 mb-1"].join(' ')}>
        { label }
      </label>

      <select
        name={name}
        value={value}
        className={[stylesList.join(' '), meta.touched && meta.error ? ' border-red-500' : 'border-slate-300'].join(' ')}
        onChange={(e) => handleSelect(e.target.value)}
        >
        <option value='' disabled>Select an option</option>
        {options.map((option: any, index: number) => (
          <option key={index} value={option.value}>{ option.name }</option>
        ))}
      </select>

      { meta.touched && meta.error ? (
        <p className="mt-1 text-xs text-red-500 flex-wrap">
          { meta.error }
        </p>
      ) : null}
    </div>
  )
}

interface SelectProps {
  name: string,
  label: string,
  options: any,
  value: any,
  meta: {
    error: any,
    touched: any
  }
  handleChange: any
}
