import axios from '../axios'

export const DepartmentFileAPI = {
  getAll: async function(departmentId: number) {
    return await axios.get(`/departments/${departmentId}/files`)
  },
  store: async function(payload: StoreFileProps)
  {
    return await axios.post('/departments/files', payload)
  },
  update: async function(payload: StoreFileProps)
  {
    return await axios.post(`/files/${payload.uuid}`, payload)
  },
}

interface StoreFileProps {
  uuid?: string,
  name: string,
  fileableId: number,
  fileableType: string
}