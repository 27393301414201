export const sierraDepartments = [
  {
    id: 1,
    name: 'Sierra Expediting',
    value: 1
  },
  {
    id: 2,
    name: 'Sierra Logistics',
    value: 2
  }
]