import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from '../views/Users/store/usersSlice'
import documentsReducer from '../views/Documents/store/documentsSlice';

export const store = configureStore({
  reducer: {
    users: userReducer,
    documents: documentsReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;