import axios from '../axios'
import { camelToSnake } from '../helpers/helpers';

export const UserAPI = {
  getMe: async function() {
    return await axios.get('/user')
  },
  getAll: async function() {
    return await axios.get(`/users`)
  },
  create: async function(payload: any){
    return await axios.post('/users', camelToSnake(payload))
  },
  get: async function(id: number) {
    return await axios.get(`/users/${id}`);
  },
  update: async function(id: number, payload: any){
    return await axios.put(`/users/${id}`, camelToSnake(payload))
  },
}