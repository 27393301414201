import React from 'react';
import PageHeader from '../../../components/PageHeader';
import Container from '../../../components/UI/Container';
import ButtonLink from '../../../components/UI/ButtonLink';
import UsersList from './UsersList/UsersList';
import Drawer from '../../../components/Drawer';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
  openCreateDrawer,
  closeCreateDrawer,
  selectIsCreateDrawerOpen
} from '../../Users/store/usersSlice';
import CreateUserForm from './CreateUserForm/CreateUserForm';
import Button from '../../../components/UI/Button';
import {useAuth} from '../../../contexts/AuthContext';

const UsersPage = () => {
  const dispatch = useAppDispatch()
  const { user}  = useAuth()
  const {
    isCreateDrawerOpen,
  } = useAppSelector(state => ({
    isCreateDrawerOpen: selectIsCreateDrawerOpen(state),
  }));

  return (
    <>
      <PageHeader title='Users' description='A list of all the users in your account including their name, title, email and role.'>
        { user.isAdmin &&
          <Button onClick={() => dispatch(openCreateDrawer())}>Add User</Button>
        }
      </PageHeader>

      <Drawer
        title='Create User'
        isOpened={isCreateDrawerOpen}
        toggleDrawer={() => dispatch(closeCreateDrawer())}
      >
        <CreateUserForm />
      </Drawer>

      <Container maxW='max-w-full'>
        <UsersList />
      </Container>
    </>
  );
};

export default UsersPage;