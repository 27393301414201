import axios from '../axios'

export const FileAPI = {
  get: async function(uuid: string | null) {
    return await axios.get(`/files/${uuid}`, {
      headers: {
        responseType: 'blob'
      }
    })
  },
  store: async function(payload: any)
  {
    return await axios.post(`/files`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  update: async function(uuid: string, payload: any)
  {
    return await axios.patch(`/files/${uuid}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  delete: async function(uuid: string) {
    return await axios.delete(`files/${uuid}`);
  }
}