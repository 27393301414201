import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import type { RootState } from '../../../store/store';
import {DepartmentFileAPI} from '../../../api/DepartmentFileAPI';

interface DocumentProps {
  id: number,
  uuid: string,
  name: string,
  filename: string,
  type: string,
  size: number,
  path: string,
  fileableId: number,
  fileableType: string,
  createdAt: string,
  updatedAt: string,
}

export interface CreateDocumentStateProps {
  documents: {
    data: DocumentProps[] | null,
    departmentId: number,
    isLoading: boolean,
    error: string | null
  },
  createDocument: {
    isDrawerOpen: boolean
  },
  editDocument: {
    uuid: string | null
    isDrawerOpen: boolean
  }
}

const initialState: CreateDocumentStateProps = {
  documents: {
    data: null,
    departmentId: 1,
    isLoading: true,
    error: null
  },
  createDocument: {
    isDrawerOpen: false
  },
  editDocument: {
    uuid: null,
    isDrawerOpen: false
  }
}

export const documentSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDepartmentId: (state, action: PayloadAction<number>) => {
      state.documents.departmentId = action.payload
    },
    openCreateDrawer: state => {
      state.createDocument.isDrawerOpen = true
    },
    closeCreateDrawer: state => {
      state.createDocument.isDrawerOpen = false
    },
    openEditDrawer: (state, action: PayloadAction<string>) => {
      state.editDocument.uuid = action.payload
      state.editDocument.isDrawerOpen = true
    },
    closeEditDrawer: state => {
      state.editDocument.isDrawerOpen = false
      state.editDocument.uuid = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocuments.pending, (state): void => {
        state.documents.isLoading = true
        state.documents.error = null
      })
      .addCase(fetchDocuments.fulfilled, (state, action: PayloadAction<any>): void => {
        state.documents.isLoading = false
        state.documents.data = action.payload.data.data
      })
      // .addCase(fetchDocuments.rejected, (state, action: PayloadAction<string>): void => {
      //   state.documents.isLoading = false
      //   state.documents.data = action.payload
      // })
  }
})

export const {
  setDepartmentId,
  openCreateDrawer,
  closeCreateDrawer,
  openEditDrawer,
  closeEditDrawer
} = documentSlice.actions
export const selectIsLoading = (state: RootState): any => state.documents.documents.isLoading
export const selectDocuments = (state: RootState): any => state.documents.documents.data
export const selectDepartmentId = (state: RootState): number => state.documents.documents.departmentId
export const selectIsCreateDrawerOpen = (state: RootState): boolean => state.documents.createDocument.isDrawerOpen
export const selectIsEditDrawerOpen = (state: RootState): boolean => state.documents.editDocument.isDrawerOpen
export const selectEditDocumentUUID = (state: RootState): string | null => state.documents.editDocument.uuid
export default documentSlice.reducer

export const fetchDocuments = createAsyncThunk('documents/fetchDocuments', async (departmentId: number) => {
  return DepartmentFileAPI.getAll(departmentId)
})