import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
  BellIcon,
  CogIcon,
} from '@heroicons/react/24/outline'
import '../../App.css';
import TouchNavigation from '../Navigation/TouchNavigation';
import DesktopNavigation from '../Navigation/DesktopNavigation';
import {UserAPI} from '../../api/UserAPI';
import {useAuth} from '../../contexts/AuthContext';
import UserDropdown from '../UserDropdown';
import Loading from '../UI/Loading';
import Icon from '../UI/Icon';
import axios from '../../axios';

export default function DashboardLayout({children}: {children: React.ReactNode}) {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const {user, setUser} = useAuth()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    UserAPI.getMe()
      .then(response => setUser(response.data.data))
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem('user');
          navigate('/login', )
        }
      })
      .finally(() => setIsLoading(false))

    document.body.classList.add('bg-neutral-50')
  }, []);

  const handleLogout = async () => {
    try {
      const resp = await axios.post('/logout');
      if (resp.status === 200) {
        localStorage.removeItem('user');
        window.location.href = '/login'
      }
    } catch (error) {
      console.log(error);
    }
  };

  if(isLoading) return <Loading/>

  if(!user) {
    window.location.href = '/login'
  }

  return (
    <div className=''>

      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-24 shrink-0 items-center mt-6">
                    <img
                      className="h-24 w-auto"
                      src="/logo.svg"
                      alt="Sierra Expediting"
                    />
                  </div>
                  <TouchNavigation/>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col border-r-2 border-neutral-100">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex h-full flex-col overflow-y-auto bg-white px-4">
          <div className="flex justify-center my-6 shrink-0 items-center">
            <img
              className="h-24 w-auto"
              src="/logo.svg"
              alt="Your Company"
            />
          </div>

          <DesktopNavigation />
        </div>
      </div>

      <div className="lg:pl-72">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b-2 border-neutral-100 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

          <div className="flex flex-1 gap-x-4 self-stretch justify-end lg:gap-x-6">
            <div className="flex items-center gap-x-4 lg:gap-x-6">

              {/*<button type="button" className="-m-2.5 p-2.5 text-gray-900 hover:text-sierra-blue">*/}
              {/*  <span className="sr-only">Get signature</span>*/}
              {/*  <Icon prefix='fal' icon='signature' size='lg' />*/}
              {/*  <span className='text-sm uppercase font-semibold ml-1'>E-mail Signature</span>*/}
              {/*</button>*/}

              {/*<div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />*/}

              <UserDropdown id={user.id} firstName={user.firstName} lastName={user.lastName} email={user.email} />

              <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

              <button type="button" onClick={() => handleLogout()} className="-m-2.5 p-2.5 text-red-500 hover:text-red-600">
                <span className="sr-only">Sign out</span>
                <Icon prefix='fal' icon='power-off' size='lg' />
              </button>
            </div>
          </div>
        </div>

        <main className='py-10'>
          <Outlet />
        </main>
      </div>
    </div>
  );
};