import React, {useState} from 'react';
import { Helmet } from 'react-helmet'
import PageHeader from '../../../components/PageHeader';
import DocumentsList from '../DocumentsList/DocumentsList';
import Tabs from '../../../components/UI/Tabs/Tabs';
import Tab from '../../../components/UI/Tabs/Tab';
import Button from '../../../components/UI/Button';
import UploadDocumentForm from '../UploadDocumentForm/UploadDocumentForm';
import Drawer from '../../../components/Drawer';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
  openCreateDrawer,
  closeCreateDrawer,
  closeEditDrawer,
  setDepartmentId,
  selectIsCreateDrawerOpen,
  selectIsEditDrawerOpen,
  selectEditDocumentUUID,
  selectDepartmentId
} from '../store/documentsSlice';
import {useAuth} from '../../../contexts/AuthContext';

const DocumentIndex = () => {
  const {user} = useAuth()
  const [tabs, setTabs] = useState([
    { id: 1, name: 'Sierra Expediting', isActive: true },
    { id: 2, name: 'Sierra Logistics', isActive: false },
  ])

  const {
    departmentId,
    isCreateDrawerOpen,
    isEditDrawerOpen,
    editDocumentUUID
  } = useAppSelector(state => ({
    departmentId: selectDepartmentId(state),
    isCreateDrawerOpen: selectIsCreateDrawerOpen(state),
    isEditDrawerOpen: selectIsEditDrawerOpen(state),
    editDocumentUUID: selectEditDocumentUUID(state)
  }));

  const dispatch = useAppDispatch()

  const [currentActiveTabId, setCurrentActiveTabId] = useState(1)
  const setActiveTab = (id: number): void => {
    setTabs((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, isActive: true } : { ...item, isActive: false }
      ),
    );
    dispatch(setDepartmentId(id))
    setCurrentActiveTabId(id)
  }

  return (
    <>
      <Helmet>
        <title>Documents - Sierra Expediting, Inc</title>
      </Helmet>

      <PageHeader title='Documents' description='Download up-to-date corporate documents in just a one click'>
        { user.isAdmin &&
          <Button onClick={() => dispatch(openCreateDrawer())}>Add Document</Button>
        }
      </PageHeader>

      <Drawer
        title='Add File'
        isOpened={isCreateDrawerOpen}
        toggleDrawer={() => dispatch(closeCreateDrawer())}
      >
        <UploadDocumentForm />
      </Drawer>

      <Tabs tabs={tabs} setActiveTab={(id: number) => setActiveTab(id)} />

      {tabs.map(tab => (
        <Tab isActive={currentActiveTabId === tab.id}>
          <div className='p-4 md:p-6 lg:p-8'>
            <DocumentsList departmentId={departmentId}  />
          </div>
        </Tab>
      ))}
    </>
  );
};

export default DocumentIndex;