import React from 'react';
import { createContext, useContext, useState } from 'react';
import axios from '../axios';

const apiUrl = process.env.REACT_APP_API_HOST;

const AuthContent = createContext({
  user: {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@domain.com',
    isAdmin: false
  },
  setUser: (user: UserProps) => {},
  csrfToken: () => {},
});

export const AuthProvider = ({ children }: {children: React.ReactNode}) => {
  const [user, _setUser] = useState(
    // @ts-ignore
    JSON.parse(localStorage.getItem('user')) || null
  );

  // set user to local storage
  const setUser = (user: UserProps) => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
    _setUser(user);
  };

  // csrf token generation for guest methods
  const csrfToken = async () => {
    await axios.get(`${apiUrl}/sanctum/csrf-cookie`);
    return true;
  };

  return (
    // @ts-ignore
    <AuthContent.Provider value={{ user, setUser, csrfToken }}>
      {children}
    </AuthContent.Provider>
  );
};

interface UserProps {
  user: {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    isAdmin: boolean
  },
  token: string,
}

export const useAuth = () => {
  return useContext(AuthContent);
};