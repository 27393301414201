import React from 'react';
import Icon from './Icon';

const Loading = () => {
  return (
    <div className='flex w-full h-screen items-center justify-center'>
      <span className='font-bold text-slate-600'>
        <Icon prefix='fas' icon='spinner' size='2xl'/>
      </span>
    </div>
  );
};

export default Loading;