import React from 'react';
import Icon from './Icon';

const EmptySet = () => {
  return (
    <div className="bg-white border-2 border-neutral-100 rounded-md p-8">
      <div className="flex flex-col justify-center items-center">
        <div className="flex-shrink-0 text-gray-300">
          <Icon prefix='fal' icon='empty-set' size='4x' />
        </div>
        <p className="flex mt-3 text-sm text-gray-400 font-bold uppercase tex">
          Empty Set
        </p>
      </div>
    </div>
  );
};

export default EmptySet;