import React from 'react';
import {IconName, IconPrefix, SizeProp} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon: React.FC<IconProps> = ({ prefix, icon, size  }) => {
  return <FontAwesomeIcon icon={[prefix as IconPrefix, icon as IconName]} size={size as SizeProp} />
};

interface IconProps {
  prefix: string,
  icon: string,
  size?: string
}

export default Icon;