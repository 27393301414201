import React from 'react';

const Tabs = ({tabs, setActiveTab}: TabsProps) => {

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div>
      <div className="sm:hidden px-4">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-slate-300 py-2 pl-3 pr-10 text-base focus:border-sierra-blue focus:outline-none focus:ring-sierra-blue sm:text-sm"
          // defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab: TabProps) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b-2 border-neutral-200 px-4 sm:px-6 lg:px-8">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab: TabProps) => (
              <div
                key={tab.name}
                className={classNames(
                  tab.isActive
                    ? 'border-sierra-blue text-sierra-blue'
                    : 'border-transparent text-slate-500 hover:border-sierra-blue hover:text-sierra-blue',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-bold cursor-pointer'
                )}
                aria-current={tab.isActive ? 'page' : undefined}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.isActive ? 'bg-sierra-blue text-white' : 'bg-slate-200 text-slate-900',
                      'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

interface TabsProps {
  tabs: TabProps[],
  setActiveTab: any
}

interface TabProps {
  id: number,
  name: string,
  count?: number,
  isActive: boolean
}

export default Tabs;