import React from 'react';
import { Link } from 'react-router-dom'
import Icon from '../UI/Icon';

const navigationLinks = [
  { name: 'Carriers', href: '/', icon: 'buildings', current: false, isAvailable: false },
  { name: 'Trucks', href: '/', icon: 'truck', current: false, isAvailable: false },
  { name: 'Loads', href: '/', icon: 'pallet-boxes', current: false, isAvailable: false },
]

const mainLinks = [
  { name: 'Dashboard', href: '/', icon: 'grid-horizontal', current: false, isAvailable: false },
  { name: 'Team', href: '/', icon: 'users', current: false, isAvailable: false },
  { name: 'Customers', href: '/', icon: 'user-tie', current: false, isAvailable: false },
]

const companyLinks = [
  { name: 'Documents', href: '/documents', icon: 'files', current: true, isAvailable: true },
]

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

const TouchNavigation = () => {
  return (
    <nav className="flex flex-1 flex-col">
      <ul role="list" className="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" className="-mx-2 space-y-1">
            {navigationLinks.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.isAvailable ? item.href : '#'}
                  className={[classNames(
                    item.current
                      ? 'bg-neutral-200 text-black'
                      : 'text-black hover:bg-neutral-100',
                    'group flex gap-x-2 rounded-md py-1.5 px-2 text-sm leading-6 font-semibold'
                  ), classNames(
                    item.isAvailable
                      ? ''
                      : 'opacity-70'
                  )].join(' ')}
                >
                  {item.icon &&
                    <span className='w-6'>
                      <Icon prefix='fal' icon={item.icon} size='lg'/>
                    </span>
                  }
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <div className="text-xs font-semibold leading-6 text-gray-400">Logistics</div>
          <ul role="list" className="-mx-2 mt-2 space-y-1">
            {mainLinks.map((item) => (
              <Link to={item.href} key={item.name}
                  className={[classNames(
                    item.current
                      ? 'bg-neutral-200 text-black'
                      : 'text-black hover:bg-neutral-100',
                    'group flex gap-x-2 rounded-md py-1.5 px-2 text-sm leading-6 font-semibold'
                  ), classNames(
                    item.isAvailable
                      ? ''
                      : 'opacity-70'
                  )].join(' ')}
                >
                {item.icon &&
                  <span className='w-6'>
                      <Icon prefix='fal' icon={item.icon} size='lg'/>
                    </span>
                }
                  <span className="truncate">{item.name}</span>
                </Link>
            ))}
          </ul>
        </li>
        <li>
          <div className="text-xs font-semibold leading-6 text-gray-400">Company</div>
          <ul role="list" className="-mx-2 mt-2 space-y-1">
            {companyLinks.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className={[classNames(
                    item.current
                      ? 'bg-neutral-200 text-black'
                      : 'text-black hover:bg-neutral-100',
                    'group flex gap-x-2 rounded-md py-1.5 px-2 text-sm leading-6 font-semibold'
                  ), classNames(
                    item.isAvailable
                      ? ''
                      : 'opacity-70'
                  )].join(' ')}
                >
                  {item.icon &&
                    <span className='w-6'>
                      <Icon prefix='fal' icon={item.icon} size='lg'/>
                    </span>
                  }
                  <span className="truncate">{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default TouchNavigation;