import React from 'react';

const Card = ({ title, width, margin, isPaddingless = false, children }: CardProps) => {
  return (
    <div className={[width, margin, "w-full divide-y divide-slate-200 overflow-hidden rounded-lg bg-white border-2 border-slate-100"].join('')}>
      { title &&
        <div className={isPaddingless ? '' : 'px-4 py-4 sm:px-6'}>
          <span className='font-semibold leading-7 text-gray-900'>{ title }</span>
        </div>
      }
      <div className={isPaddingless ? '' : 'px-4 py-4 sm:p-6'}>{ children }</div>
    </div>
  );
};

interface CardProps {
  title?: string,
  width?: string,
  margin?: string,
  isPaddingless?: boolean,
  children: React.ReactNode,

}

export default Card;