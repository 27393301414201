import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faFilePdf,
  faDownload,
  faTrashAlt,
  faPencil,
  faEmptySet,
  faLock,
  faGridHorizontal,
  faBuildings,
  faTruck,
  faFiles,
  faPalletBoxes,
  faUserTie,
  faUsers,
  faTrashCan,
  faBell,
  faSignature,
  faPowerOff,
  faEnvelope,
} from '@fortawesome/pro-light-svg-icons';
import {faFile, faPhonePlus} from '@fortawesome/pro-thin-svg-icons'
import {faSpinner, faPlus} from '@fortawesome/free-solid-svg-icons'

library.add(faFilePdf, faDownload, faFile, faSpinner, faTrashAlt, faPencil, faEmptySet, faLock, faGridHorizontal, faBuildings, faTruck, faFiles, faPalletBoxes, faPlus, faUserTie, faUsers, faTrashCan, faBell, faSignature, faPowerOff, faPhonePlus, faEnvelope)