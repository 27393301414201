import React, {useState} from 'react';
import Input from '../../../../components/UI/Input';
import Button from '../../../../components/UI/Button';
import {Formik, Form } from 'formik';
import { createUserSchema } from '../../../../lib/validationSchemas'
import { UserAPI } from '../../../../api/UserAPI';
import {toast} from 'react-toastify';
import {useAppDispatch} from '../../../../store/hooks';
import {closeCreateDrawer, fetchUsers} from '../../store/usersSlice'

const CreateUserForm = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const initialValues: initialValuesProps = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createUserSchema}
      onSubmit={(values, actions) => {
        setIsLoading(true)
        UserAPI.create(values)
          .then(() => {
            toast.success('User successfully created')
            dispatch(fetchUsers())
            dispatch(closeCreateDrawer())
            actions.resetForm()
          })
          .catch(error => {
            actions.setErrors(error.response.data.errors)
            toast.error(error.message)
          })
          .finally(() => setIsLoading(false))
      }}
    >
      {({submitForm}) => (
        <Form className='grid grid-cols-1 gap-4 py-6'>
          <Input type='text' label='First name' name='firstName'/>

          <Input type='text' label='Last name' name='lastName'/>

          <Input type='email' label='E-mail' name='email'/>

          <Input type='password' label='Password' name='password'/>

          {/*<div className="sm:col-span-3">*/}
          {/*  <Input type='phone' label='Phone number' name='phoneNumber' mask='+1 (999) 999-9999' placeholder='+1 (___) ___-____' />*/}
          {/*</div>*/}

          {/*<div className="sm:col-span-3">*/}
          {/*  <Input type='string' label='Position' name='position'/>*/}
          {/*</div>*/}

          {/*<div className="sm:col-span-3">*/}
          {/*  <Select*/}
          {/*    options={departments}*/}
          {/*    label='Department'*/}
          {/*    name='department'*/}
          {/*    value={values.department}*/}
          {/*    meta={{touched: touched.department, error: errors.department }}*/}
          {/*    handleChange={({name, value}: any) => setFieldValue(name, value)}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className='flex justify-end mt-6'>
            <Button isLoading={isLoading} onClick={() => submitForm()}>Create</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

interface initialValuesProps {
  firstName: string,
  lastName: string,
  email: string,
  password: string
}

export default CreateUserForm;