import { ReactNode, EventHandler, MouseEvent } from 'react';
import Icon from './Icon';

const Button = ({ type = 'button', size = 'md', color = 'sierra', isFullwidth = false, isLoading = false, onClick, children }: ButtonProps) => {
  const stylesList: string[] = ['rounded-lg bg-indigo-600 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2' +
  ' transition-colors duration-500']

  applyStyles()

  function applyStyles(): void {
    const sizeStyle:string = getSize(size)
    const colorStyle:string = getColor(color)
    stylesList.push(
      sizeStyle,
      colorStyle,
      isFullwidth ? 'w-full' : ''
    )
  }

  function getSize(size: string):string {
    switch(size) {
      case 'xs':
        return 'px-2 py-1 text-xs'
      case 'sm':
        return 'px-2 py-1 text-sm'
      case 'lg':
        return 'px-3.5 py-2.5 text-sm'
      case 'xl':
        return 'px-4 py-2.5 text-sm'
      default:
        return 'px-3 py-2 text-sm'
    }
  }
  function getColor(color: string):string {
    switch(color) {
      case 'black':
        return 'bg-neutral-900 border border-transparent text-white hover:bg-neutral-800 hover:border-neutral-900'
      case 'red':
        return 'bg-red-500 border border-transparent text-white hover:text-red-500 hover:bg-white hover:border-red-500'
      default:
        return 'bg-sierra-blue text-white border border-transparent hover:text-sierra-blue hover:bg-white hover:border-sierra-blue' +
          ' focus-visible:outline-sierra-blue'
    }
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={stylesList.join(' ')}
    >
      { isLoading
        ? <span className=''><Icon prefix='fas' icon='spinner' /></span>
        : children
      }
    </button>
  );
};

interface ButtonProps {
  type?: 'submit' | 'reset' | 'button' | undefined,
  size?: string ,
  color?: string,
  isFullwidth?: boolean,
  isLoading?: boolean,
  onClick?: EventHandler<MouseEvent>,
  children: ReactNode
}

export default Button;