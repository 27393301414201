import React, {useState} from 'react';
import Input from './UI/Input';
import Button from './UI/Button';
import {Formik, Form} from 'formik';
import axios from '../axios';
import { useAuth } from '../contexts/AuthContext';
import { loginSchema } from '../lib/validationSchemas'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { user, setUser, csrfToken } = useAuth()

  const handleSubmit = async (values: LoginFormProps) => {
    await csrfToken();
    try {
      return await axios.post('/login', values);
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  };

  if(user) window.location.href = '/documents'

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={loginSchema}
        onSubmit={(values: LoginFormProps, actions): void => {
          setIsLoading(true)
          handleSubmit(values)
            .then((res) => {
              setUser(res.data)
            })
            .then(() => navigate('/documents', { replace: true}))
            // .then(() => actions.resetForm())
            .catch(error => toast.error(error.message))
            .finally(() => setIsLoading(false))
        }}>
        <Form className="space-y-6" noValidate={true}>
          <Input theme='dark' type='email' label='E-mail' name='email' />

          <Input theme='dark' type='password' label='Password' name='password' />

          <Button type='submit' size='lg' isFullwidth={true} isLoading={isLoading}>Sign in</Button>
        </Form>
      </Formik>
    </>
  );
};

interface LoginFormProps {
  email: string,
  password: string
}

export default Login;