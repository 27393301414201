import React from 'react';

const Container = ({isVertical, maxW,  children}: {isVertical?: boolean, maxW: string, children: React.ReactNode}) => {
  const stylesList: string[] = ['mx-auto px-4 sm:px-6 lg:px-8']

  if(isVertical) stylesList.push(' flex-col')

  return (
    <div className={['flex', stylesList, maxW].join(' ')}>
      { children }
    </div>
  );
};

export default Container;