import React, { useEffect } from 'react';
import Badge from '../../../../components/UI/Badge';
import {Link} from 'react-router-dom';
import UsersListSkeleton from './UsersListSkeleton';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {selectUsers, selectIsLoading, fetchUsers} from '../../store/usersSlice';
import Empty from '../../../../components/UI/Empty';
import Icon from '../../../../components/UI/Icon';
import Container from '../../../../components/UI/Container';

const UsersList = () => {
  const dispatch = useAppDispatch()
  const {
    isLoading,
    users,
  } = useAppSelector(state => ({
    isLoading: selectIsLoading(state),
    users: selectUsers(state),
  }));

  useEffect(() => {
    dispatch(fetchUsers())
  }, []);

  const getInitials = (firstName, lastName):string => {
    let first = firstName.charAt(0)
    let last = lastName.charAt(0)
    return `${first} ${last}`
  }

  if(isLoading) return <UsersListSkeleton/>
  if(!users.length) return <Empty/>

  return (
    <div className="w-full grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {users.length > 0 &&
        users.map(user => (
          <div key={user.email} className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
            <div className="flex items-center flex-1 flex-col p-8">
                <span className="inline-flex h-32 w-32 items-center justify-center rounded-full bg-neutral-500">
                  <span className="font-medium leading-none text-white text-3xl capitalize">
                    { getInitials(user.firstName, user.lastName) }
                  </span>
                </span>
              <h3 className="mt-6 text-sm font-medium text-gray-900">{user.firstName} {user.lastName}</h3>
              {/*<dl className="mt-1 flex flex-grow flex-col justify-between">*/}
              {/*  <dt className="sr-only">Title</dt>*/}
              {/*  <dd className="text-sm text-gray-500">{person.title}</dd>*/}
              {/*  <dt className="sr-only">Role</dt>*/}
              {/*  <dd className="mt-3">*/}
              {/*<span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">*/}
              {/*  {person.role}*/}
              {/*</span>*/}
              {/*  </dd>*/}
              {/*</dl>*/}
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="flex w-0 flex-1">
                  <a
                    href={`mailto:${user.email}`}
                    className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  >
                    <Icon prefix='fal' icon='envelope'/>
                    Email
                  </a>
                </div>
                <div className="-ml-px flex w-0 flex-1">
                  <a
                    href=''
                    className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  >
                    <Icon prefix='fal' icon='pencil'/>
                    Edit
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default UsersList;