import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import type { RootState } from '../../../store/store';
import {UserAPI} from '../../../api/UserAPI';

interface UserProps {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  isAdmin: boolean
}

export interface CreateUserStateProps {
  users: {
    data: UserProps[] | null,
    isLoading: boolean,
    error: string | null
  },
  createUser: {
    isDrawerOpen: boolean
  },
}

const initialState: CreateUserStateProps = {
  users: {
    data: null,
    isLoading: true,
    error: null
  },
  createUser: {
    isDrawerOpen: false
  },
}

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    openCreateDrawer: state => {
      state.createUser.isDrawerOpen = true
    },
    closeCreateDrawer: state => {
      state.createUser.isDrawerOpen = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state): void => {
        state.users.isLoading = true
        state.users.error = null
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<any>): void => {
        state.users.isLoading = false
        state.users.data = action.payload.data.data
      })
  }
})

export const {
  openCreateDrawer,
  closeCreateDrawer
} = userSlice.actions
export const selectIsLoading = (state: RootState): any => state.users.users.isLoading
export const selectUsers = (state: RootState): any => state.users.users.data
export const selectIsCreateDrawerOpen = (state: RootState): boolean => state.users.createUser.isDrawerOpen
export default userSlice.reducer

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  return UserAPI.getAll()
})