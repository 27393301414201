'use client'

import { useState } from 'react';
import React from 'react';
import Icon from './Icon';

const UploadFile = ({initFile, icon, name, validExtensions, maxSizeInMB, meta, onChange}: UploadFileProps) => {
  const [file, setFile] = useState<any>(initFile)

  const handleFile = (payload: any): void => {
    setFile(payload)
    onChange(payload)
  }

  const removeFile = (): void => {
    setFile(null)
    onChange(null)
  }

  return (
    <div className='flex flex-col'>
      <div
        className={['flex justify-center rounded-lg border border-dashed px-6 py-10',
          meta.touched && meta.error ? ' border-red-500' : 'border-gray-900/25'].join(' ')}>
        { file
          ?
          <div className='flex flex-col text-sm rounded-lg'>
            <div className='flex mt-1'>
              <div className='bg-slate-100 text-slate-600 py-2 px-4 rounded-l-lg'>
                { file.name }
                <span className='ml-1'>({ (file.size / 1000000).toFixed(2) }MB)</span>
              </div>
              <div className='bg-red-500 hover:bg-red-600 py-2 px-4 rounded-r-lg cursor-pointer' onClick={removeFile}>
                <span className='text-white'><Icon prefix='fal' icon='trash-alt' /></span>
              </div>
            </div>
          </div>
          :
          <div className="text-center">
            <Icon prefix='fat' icon={`${icon}`} size='2xl' />
            <div className="flex justify-center mt-4 text-sm leading-6 text-gray-600">
              <label
                htmlFor={name}
                className="relative cursor-pointer rounded-md bg-white font-semibold text-sierra-blue hover:text-sierra-red focus:none"
              >
                <span>Select a file</span>
                <input type='file' id={name} name={name} onChange={(e: any) => handleFile(e.target.files[0])}  className='sr-only' />
              </label>
            </div>
            <p className="text-xs leading-5 text-gray-600">
              { validExtensions.join(', ') } up to {maxSizeInMB}MB</p>
          </div>
        }
      </div>
      { meta.touched && meta.error ? (
        <p className="mt-1 text-xs text-red-500 flex-wrap">
          { meta.error }
        </p>
      ) : null}
    </div>
  );
};

interface UploadFileProps {
  initFile?: object | null,
  icon: string,
  name: string,
  validExtensions: string[],
  maxSizeInMB: number,
  meta: {
    touched: boolean | undefined,
    error: string | undefined,
  },
  onChange: any
}

export default UploadFile;