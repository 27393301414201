import React from 'react';
import { Link } from 'react-router-dom'
import Icon from '../UI/Icon';

const navigationLinks = [
  { name: 'Carriers', href: '/', icon: 'buildings', current: false, isAvailable: false },
  { name: 'Trucks', href: '/', icon: 'truck', current: false, isAvailable: false },
  { name: 'Loads', href: '/', icon: 'pallet-boxes', current: false, isAvailable: false },
]

const mainLinks = [
  { name: 'Dashboard', href: '/', icon: 'grid-horizontal', current: false, isAvailable: false },
  { name: 'Team', href: '/', icon: 'users', current: false, isAvailable: false },
  { name: 'Customers', href: '/', icon: 'user-tie', current: false, isAvailable: false },
]

const companyLinks = [
  { name: 'Documents', href: '/documents', icon: 'files', current: true, isAvailable: true },
]


function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

const DesktopNavigation = () => {
  return (
    <nav className="flex flex-col">
      <span className='text-xs uppercase font-bold text-neutral-400 ml-2'>General</span>
      <ul role="list" className="flex flex-1 flex-col mt-1">
        <li>
          <ul role="list">
            {mainLinks.map((item) => (
              <li key={item.name} className='relative'>
                <Link
                  to={item.isAvailable ? item.href : '#'}
                  className={[classNames(
                    item.current
                      ? 'bg-neutral-200 text-black'
                      : 'text-black hover:bg-neutral-100',
                    'group flex gap-x-2 rounded-md py-1.5 px-2 text-sm leading-6 font-semibold'
                  ), classNames(
                    item.isAvailable
                      ? ''
                      : 'opacity-70'
                  )].join(' ')}
                >
                  {item.icon &&
                    <span className='w-6'>
                      <Icon prefix='fal' icon={item.icon} size='lg'/>
                    </span>
                  }
                  {item.name}
                  { !item.isAvailable &&
                    <span className='absolute right-0 mr-2'>
                      <Icon prefix='fal' icon='lock' />
                    </span>
                  }
                </Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>

      <span className='text-xs uppercase font-bold text-neutral-400 ml-2 mt-6'>Logistics</span>
      <ul role="list" className="flex flex-1 flex-col mt-1">
        <li>
          <ul role="list">
            {navigationLinks.map((item) => (
              <li key={item.name} className='relative'>
                <Link
                  to={item.isAvailable ? item.href : '#'}
                  className={[classNames(
                    item.current
                      ? 'bg-white text-sierra-blue'
                      : 'text-black hover:bg-neutral-100',
                    'group flex gap-x-2 rounded-md py-1.5 px-2 text-sm leading-6 font-semibold'
                  ), classNames(
                    item.isAvailable
                    ? ''
                    : 'opacity-70'
                  )].join(' ')}
                >
                  {item.icon &&
                    <span className='w-6'>
                      <Icon prefix='fal' icon={item.icon} size='lg'/>
                    </span>
                  }
                  {item.name}
                  { !item.isAvailable &&
                    <span className='absolute right-0 mr-2'>
                      <Icon prefix='fal' icon='lock' />
                    </span>
                  }
                </Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>

      <span className='text-xs uppercase font-bold text-neutral-400 ml-2 mt-6'>Company</span>
      <ul role="list" className="flex flex-1 flex-col mt-1">
        <li>
          <ul role="list">
            {companyLinks.map((item) => (
              <li key={item.name} className='relative'>
                <Link
                  to={item.isAvailable ? item.href : '#'}
                  className={[classNames(
                    item.current
                      ? 'bg-neutral-100 text-black'
                      : 'text-black hover:bg-neutral-100',
                    'group flex gap-x-2 rounded-md py-1.5 px-2 text-sm leading-6 font-semibold'
                  ), classNames(
                    item.isAvailable
                      ? ''
                      : 'opacity-70'
                  )].join(' ')}
                >
                  {item.icon &&
                    <span className='w-6'>
                      <Icon prefix='fal' icon={item.icon} size='lg'/>
                    </span>
                  }
                  {item.name}
                  { !item.isAvailable &&
                    <span className='absolute right-0 mr-2'>
                      <Icon prefix='fal' icon='lock' />
                    </span>
                  }
                </Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default DesktopNavigation;