import React from 'react';

const Tab = ({ isActive, children }: TabProps) => {
  return (
    <div className={isActive ? 'block' : 'hidden'}>
      { children }
    </div>
  );
};

interface TabProps {
  isActive: boolean,
  children: React.ReactNode
}

export default Tab;