import * as Yup from 'yup';

const phoneNumberRegex:RegExp = /^\+1\s\(\d{3}\)\s\d{3}-\d{4}$/;
const passwordRegex:RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const loginSchema = Yup.object({
  email:
    Yup.string()
      .email()
      .label('E-mail')
      .required()
      .trim(),
  password:
    Yup.string()
      .label('Password')
      .required()
})

export const createPageSchema = Yup.object({
  title:
    Yup.string()
      .label('Title')
      .required()
      .max(255, 'Must have no more than 255 characters')
      .trim()
})

export const createUserSchema = Yup.object({
  firstName:
    Yup.string()
      .label('First name')
      .required()
      .trim(),
  lastName:
    Yup.string()
      .label('Last name')
      .required()
      .trim(),
  email:
    Yup.string()
      .required()
      .label('E-mail')
      .email()
      .trim(),
  // position:
  //   Yup.string()
  //     .required()
  //     .label('Position')
  //     .trim(),
  // department:
  //   Yup.string()
  //     .required()
  //     .label('Department'),
  // phoneNumber:
  //   Yup.string()
  //     .required()
  //     .label('Phone number')
  //     .matches(phoneNumberRegex, {message: 'Your phone number must be in format +1 (XXX) XXX-XXXX'}),
  password:
    Yup.string()
      .required()
      .label('Password')
      .min(8, 'Must have more than 8 characters')
      .max(24, 'Must have less than 24 characters')
      .matches(passwordRegex, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character')
})

export const updateUserSchema = Yup.object({
  firstName:
    Yup.string()
      .label('First name')
      .required()
      .trim(),
  lastName:
    Yup.string()
      .label('Last name')
      .required()
      .trim(),
  email:
    Yup.string()
      .required()
      .label('E-mail')
      .email()
      .trim(),
  // position:
  //   Yup.string()
  //     .required()
  //     .label('Position')
  //     .trim(),
  // department:
  //   Yup.string()
  //     .required()
  //     .label('Department'),
  // phoneNumber:
  //   Yup.string()
  //     .required()
  //     .label('Phone number')
  //     .matches(phoneNumberRegex, {message: 'Your phone number must be in format +1 (XXX) XXX-XXXX'}),
  password:
    Yup.string()
      .label('Password')
      .min(8, 'Must have more than 8 characters')
      .max(24, 'Must have less than 24 characters')
      .matches(passwordRegex, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character')
})



