import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider} from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import router from './router'
import './lib/fontawesome'
import { Provider } from 'react-redux';
import { store } from './store/store'
import { Helmet } from 'react-helmet'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <AuthProvider>
      <ToastContainer
        position='top-center'
        autoClose={3000}
        pauseOnHover={false}
        hideProgressBar={false}
        closeButton={false}
        draggable={false}
        transition={Slide}
        theme='colored'
      />
      <Helmet>
        <title>Sierra Expediting, Inc.</title>
      </Helmet>
      <RouterProvider router={router}/>
    </AuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
