import React from 'react';

const PageHeader = ({title, description, children}: PageHeaderProps) => {
  return (
    <div className="md:flex md:items-center md:justify-between mb-8 px-4 sm:px-6 lg:px-8">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          { title }
        </h2>
        { description &&
          <span className='flex text-sm mt-1'>{ description }</span>
        }
      </div>
      <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
        { children }
      </div>
    </div>
  );
};

export default PageHeader;

interface PageHeaderProps {
  title: string,
  description?: string,
  children?: React.ReactNode
}