import React, {useState} from 'react';
import { Formik, Form } from 'formik'
import Input from '../../../components/UI/Input';
import Button from '../../../components/UI/Button';
import UploadFile from '../../../components/UI/UploadFile';
import {fileUploadValidationSchema} from '../../../lib/validation/Files/uploadFileSchema';
import Select from '../../../components/UI/Select';
import {sierraDepartments} from '../../../data/data';
import {FileAPI} from '../../../api/FileAPI';
import { toast } from 'react-toastify';
import {useAppDispatch} from '../../../store/hooks';
import {closeCreateDrawer, fetchDocuments} from '../store/documentsSlice';

const UploadDocumentForm = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const validFileExtensions: string[] = ['doc', 'docx', 'pdf', 'xml', 'xls', 'xlsx', 'zip'];
  const initialValues: initialValuesProps = {
    name: '',
    fileableId: '',
    fileableType: 'department',
    file: null
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={fileUploadValidationSchema}
        onSubmit={(values, actions) => {
          setIsLoading(true)
          FileAPI.store(values)
            .then(() => dispatch(fetchDocuments(+values.fileableId)))
            .then(() => dispatch(closeCreateDrawer()))
            .catch(error => {
              actions.setErrors(error.response.data.errors)
              toast.error(error.response.statusText)
            })
            .finally(() => setIsLoading(false))
        }}
        >
        {({values, touched, errors, setFieldValue, submitForm}) => (
          <Form encType="multipart/form-data">
            <div className='flex flex-col py-6'>
              <div className='mb-4'>
                <Input type='text' name='name' label='Name' />
              </div>

              <div className='mb-4'>
                <Select
                  options={sierraDepartments}
                  label='Department'
                  name='fileableId'
                  value={values.fileableId}
                  meta={{touched: touched.fileableId, error: errors.fileableId }}
                  handleChange={({name, value}: any) => setFieldValue(name, +value)}
                />
              </div>
            </div>

            <div className='w-full'>
              <UploadFile
                name='file'
                icon='file'
                validExtensions={validFileExtensions}
                maxSizeInMB={10}
                meta={{touched: touched.file, error: errors.file}}
                onChange={(file: any) => setFieldValue('file', file)}
              />
            </div>

            <div className='flex justify-end mt-6'>
              <Button isLoading={isLoading} onClick={() => submitForm()}>Upload</Button>
            </div>

          </Form>
        )}
      </Formik>
    </>
  );
};

interface initialValuesProps {
  name: string,
  fileableId: number | string,
  fileableType: string,
  file: null | object | boolean
}

export default UploadDocumentForm;