import React from 'react';
import Card from '../../../components/UI/Card';

const DocumentsListSkeleton = () => {
  return (
    <Card isPaddingless={true}>
      <table className="min-w-full divide-y divide-gray-300">
        <tbody className="divide-y divide-gray-200">
        {[...Array(10)].map((x, i) =>
          <tr>
            <td className='pl-4 w-12'>
              <span className='flex w-6 h-6 animate-pulse bg-gray-200'></span>
            </td>
            <td className="whitespace-nowrap py-2.5 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              <span className='flex w-96 h-6 animate-pulse bg-gray-200'></span>
            </td>
            <td className="whitespace-nowrap py-2.5 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              <span className='flex w-10 h-6 animate-pulse bg-gray-200'></span>
            </td>
            <td className="whitespace-nowrap py-2.5 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              <span className='flex w-6 h-6 animate-pulse bg-gray-200'></span>
            </td>
            <td className="whitespace-nowrap px-2 py-2.5 text-sm text-gray-500">
              <span className='flex w-16 h-6 animate-pulse bg-gray-200'></span>
            </td>
            <td className="flex justify-end relative whitespace-nowrap py-2.5 pl-3 pr-3 text-right text-sm font-medium sm:pr-0">
              <span className='flex w-10 h-6 animate-pulse bg-gray-200 rounded-md'></span>
            </td>
          </tr>
        )}
        </tbody>
      </table>
    </Card>
  )
};

export default DocumentsListSkeleton;