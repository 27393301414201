import React, {useEffect} from 'react';
import Icon from '../../../components/UI/Icon'
import { FileAPI } from '../../../api/FileAPI'
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
  fetchDocuments, openEditDrawer, selectDepartmentId, selectDocuments, selectIsLoading
} from '../store/documentsSlice';
import Empty from '../../../components/UI/Empty';
import {toast} from 'react-toastify';
import {useAuth} from '../../../contexts/AuthContext';
import DocumentsListSkeleton from './DocumentsListSkeleton';
import Card from '../../../components/UI/Card';

const DocumentsList: React.FC<DocumentsProps> = ({title}) => {
  const { user } = useAuth()

  const dispatch = useAppDispatch()
  const {
    isLoading,
    documents,
    departmentId
  } = useAppSelector(state => ({
    isLoading: selectIsLoading(state),
    documents: selectDocuments(state),
    departmentId: selectDepartmentId(state)
  }));

  useEffect(() => {
    dispatch(fetchDocuments(departmentId))
  }, [departmentId])

  const getFile = (uuid: string) => {
    FileAPI.get(uuid)
      .then(response => {
        const link = document.createElement('a');
        link.target = '_blank'
        link.download = response.data.fileName
        link.href = response.data.url
        link.setAttribute('download', '')
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(err => toast.error('There was an error during try to get access to the file'))
  }

  const deleteFile = (uuid: string): void => {
    FileAPI.delete(uuid)
      .then(() => toast.success('File successfully deleted'))
      .then(() => dispatch(fetchDocuments(departmentId)))
      .catch(error => toast.error(error.message))
  }

  if(isLoading) return <DocumentsListSkeleton/>
  if(!documents.length) return <Empty/>

  return (
    <Card isPaddingless={true}>
      { title &&
        <div className='flex py-4 px-4 bg-white rounded-t-xl border-b-2 border-slate-100'>
          <span className='text-sm font-semibold text-sierra-blue uppercase'>{ title }</span>
        </div>
      }

      <div className='rounded-md'>
        <table className="min-w-full">
          <thead>
            <tr className='border-b-2 border-neutral-200'>
              <th></th>
              <th scope="col" className="w-3/4 py-3.5 pr-3 text-left text-sm font-semibold text-neutral-600 sm:pl-0">
                Name
              </th>
              <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-neutral-600 sm:pl-0">
                Size
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-600">
                Last update
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y-2 divide-neutral-100">
          {documents.length > 0 &&
            documents.map((document: any) => (
              <tr key={document.id}>
                <td className='pl-4 w-12'>
                  <Icon prefix='fal' icon={`file-${document.type}`} size='lg' />
                </td>
                <td className="w-3/4 whitespace-nowrap py-3 pr-3 text-sm font-medium text-neutral-900 sm:pl-0">
                  {document.name}
                </td>
                <td className="whitespace-nowrap py-3 pr-3 text-sm font-medium text-neutral-900 sm:pl-0">
                  { (document.size / 1000000).toFixed(2) }MB
                </td>
                <td className="whitespace-nowrap px-2 py-3 text-sm text-neutral-500">{document.updatedAt}</td>
                <td className="flex items-center justify-end relative whitespace-nowrap py-2.5 pl-3 pr-3 text-right text-sm font-medium sm:pr-4 gap-x-4">
                  <span className='text-neutral-600 hover:text-sierra-blue cursor-pointer' onClick={() => getFile(document.uuid)}>
                    <Icon prefix='fal' icon='download' size='lg'/>
                  </span>

                  { user.isAdmin &&
                    <span className='text-neutral-600 hover:text-sierra-red cursor-pointer' onClick={() => deleteFile(document.uuid)}>
                      <Icon prefix='fal' icon='trash-alt' size='lg'/>
                    </span>
                  }
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
    </Card>
  );
};

interface FileProps {
  id: number,
  uuid: string,
  name: string,
  filename: string,
  type: string,
  size: number,
  path: string,
  createdAt: string,
  updatedAt: string
}

interface DocumentProps {
  id: number,
  uuid: string,
  name: string,
  type: string,
  size: number,
  updatedAt: string,
}

interface DocumentsProps {
  title?: string,
  departmentId: number
}
export default DocumentsList;