import Axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const axios = Axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
});

export default axios;