import React from 'react';
import InputMask from 'react-input-mask'
import {useField} from 'formik';

const Input = ({theme = 'light', type, label, mask, placeholder, isDisabled = false, ...props}: InputProps) => {
  const stylesList: string[] = ['block border w-full rounded-md py-1.5 px-4 sm:text-sm sm:leading-6 disabled:cursor-not-allowed']
  const [field, meta] = useField(props);

  applyStyles()

  function applyStyles(): void {
    const themeStype:string = getTheme(theme)
    stylesList.push(
      themeStype
    )
  }

  function getTheme(theme: string):string {
    if(theme === 'light')
      return 'bg-white text-slate-900 disabled:bg-slate-50 disabled:text-slate-500 disabled:ring-slate-200'
    return 'bg-slate-800 border-slate-700 text-white'
  }

  return (
    <div className='flex flex-col'>
      <label htmlFor={field.name} className={["block text-sm font-medium leading-6 mb-1", theme === 'light' ? 'text-slate-900' : 'text-white'].join(' ')}>
        { label }
      </label>

      { mask
        ?
        <InputMask
          {...field}
          {...props}
          name={field.name}
          type={type}
          mask={mask}
          placeholder={placeholder}
          className={[stylesList.join(' '), meta.touched && meta.error ? ' border-red-500' : 'border-slate-300'].join(' ')}
          disabled={isDisabled}
        />
        :
        <input
          {...field}
          {...props}
          type={type}
          name={field.name}
          className={[stylesList.join(' '), meta.touched && meta.error ? ' border-red-500' : 'border-slate-300'].join(' ')}
          placeholder={placeholder}
          disabled={isDisabled}
        />
      }

      { meta.touched && meta.error ? (
        <p className="mt-1 text-xs text-red-500 flex-wrap">
          { meta.error }
        </p>
      ) : null}
    </div>
  );
};

interface InputProps {
  theme?: string,
  type: string
  name: string,
  label?: string,
  mask?: string,
  placeholder?: string,
  isDisabled?: boolean,
}

export default Input;